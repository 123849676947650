<template>
  <div class="border rounded-md border-primary-off-white-dark bg-primary-off-white p-6">
    <div class="flex justify-between flex-wrap md:flex-none md:gap-4">
      <div class="flex items-center gap-4">
        <img
          src="https://cdn.prod.website-files.com/63da9ce0049e626ceff25b70/63fa88a8f8657172830165bc_Aryeo_Logo_-_Purple_square_white_mark-high_1_256x256.png"
          alt="Aryeo Lite"
          class="object-contain w-12 h-12 rounded-md"
        />
        <div class="flex flex-col gap-x-6">
          <Typography variant="h2" class="font-semibold !text-2xl"
            >Welcome to Aryeo Lite!</Typography
          >
          <Typography variant="body-large">
            This a free account for you and your team to create listings and deliver media.
          </Typography>
        </div>
      </div>

      <div class="flex flex-none items-center gap-x-5">
        <TextButton @click="handleCloseAryeoLite" text="Close" />
        <Button @click="showLearnMoreModal = true" text="Learn More" />
      </div>
    </div>
  </div>
  <teleport to="body">
    <AryeoLiteLearnMoreModal v-model:showLearnMoreModal="showLearnMoreModal" />
  </teleport>
</template>

<script setup>
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { Button, TextButton, Typography } from '@aryeo/runway'
import AryeoLiteLearnMoreModal from '@/Components/Company/Organization/Modal/AryeoLiteLearnMoreModal.vue'
const closeAryeoLiteForm = useForm({})
const showLearnMoreModal = ref(false)
const handleCloseAryeoLite = () => {
  closeAryeoLiteForm.put(route('web.company.aryeo-lite.learn-more.update'))
}
</script>
