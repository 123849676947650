<template>
  <Modal
    @onClose="showLearnMoreModal = false"
    :is-open="showLearnMoreModal"
    titleText="Learn more: Aryeo Lite"
    :isWide="true"
  >
    <template #default>
      <div class="space-y-8 mt-3">
        <div class="flex items-center gap-4">
          <Icon name="photovideo" size="large" color="subtle" />
          <Typography variant="body">
            Create unlimited listings and deliver media to your customers.
          </Typography>
        </div>
        <div class="flex items-center gap-4">
          <Icon name="download" size="large" color="subtle" />
          <Typography variant="body">
            Your customers can access their media through a download center link or their own
            customer portal.
          </Typography>
        </div>
        <div class="flex items-center gap-4">
          <Icon name="users" size="large" color="subtle" />
          <Typography variant="body">
            Add team members to your company to manage listings.
          </Typography>
        </div>
        <div class="flex items-center gap-4">
          <Icon name="handshake" size="large" color="subtle" />
          <Typography variant="body">
            Through a partnership with ShowingTime+, agents can place media requests that appear
            directly in your Aryeo Lite portal and the delivered media appears directly on their
            dashboard.
          </Typography>
        </div>
        <div class="flex items-center gap-4">
          <Icon name="100" size="large" color="subtle" />
          <Typography variant="body"> Aryeo Lite is always 100% free to use. </Typography>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { Icon, Modal, Typography } from '@aryeo/runway'
const showLearnMoreModal = defineModel('showLearnMoreModal')
</script>
