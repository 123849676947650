import {
  CalendarIcon,
  ChartSquareBarIcon,
  ChipIcon,
  ClockIcon,
  CogIcon,
  TruckIcon,
  CurrencyDollarIcon,
  DesktopComputerIcon,
  DeviceTabletIcon,
  DocumentTextIcon,
  HomeIcon,
  LightningBoltIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  UserAddIcon,
  UsersIcon,
} from '@heroicons/vue/outline'

import { usePermissions } from '@/Hooks/Company/usePermissions'
import { useAuth } from '@/Hooks/Company/useAuth'

export default function usePrimaryNavigation() {
  const { authCompanyUser, authCompany, authCompanyTeamMember, authOrganization } = useAuth()

  const { hasPermission } = usePermissions()

  return {
    overviewRouteName: null,
    sections: [
      {
        show: true,
        name: null,
        description: null,
        children: [
          {
            show: authCompanyUser.value.is_super,
            name: 'Aryeo Mission Control',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'admin.web.legacy.houston-overview.show',
            icon: ChipIcon,
            routeNamesToCheckIfCurrent: ['admin.web.legacy.houston.*'],
          },
          {
            show: authOrganization.value.is_pro_account,
            name: 'Dashboard',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.company.dashboard.show',
            icon: DesktopComputerIcon,
            routeNamesToCheckIfCurrent: ['v2.dashboard.*'],
          },
        ],
      },
      {
        show: true,
        name: 'General',
        description: null,
        children: [
          {
            show: true,
            name: 'Listings',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.company.listings.index',
            icon: HomeIcon,
            routeNamesToCheckIfCurrent: [
              'web.company.listings.*',
              'web.company.marketing-center.*',
            ],
          },
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Orders',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.company.orders.index',
            icon: DocumentTextIcon,
            routeNamesToCheckIfCurrent: ['web.company.orders.*'],
          },
          {
            show:
              authCompanyTeamMember.value.is_service_provider || hasPermission('calendar_view_any'),
            disabled: !authOrganization.value.is_pro_account,
            name: 'Calendar',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.company.calendar.index',
            icon: CalendarIcon,
            routeNamesToCheckIfCurrent: ['web.company.calendar.index'],
          },
          {
            show: hasPermission('customer_view_any'),
            name: 'Customers',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.company.customer-users.index',
            icon: UsersIcon,
            routeNamesToCheckIfCurrent: null,
          },
        ],
      },
      {
        show: true,
        name: 'Storefront',
        description: null,
        children: [
          {
            show: hasPermission('product_view_any'),
            disabled: !authOrganization.value.is_pro_account,
            name: 'Products',
            description: 'The primary services you offer on your order forms.',
            routeIsInertia: true,
            targetRouteName: 'web.company.products.overview.show',
            icon: ShoppingCartIcon,
            routeNamesToCheckIfCurrent: null,
          },
          {
            show: true,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Availability',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.company.availability.overview.show',
            icon: ClockIcon,
            routeNamesToCheckIfCurrent: [],
          },
          {
            show: hasPermission('order_form_view_any'),
            disabled: !authOrganization.value.is_pro_account,
            name: 'Order Forms',
            description: 'Begin collecting new orders setting up your first order form.',
            routeIsInertia: true,
            targetRouteName: 'web.company.order-forms.index',
            icon: ShoppingBagIcon,
            routeNamesToCheckIfCurrent: ['web.company.order-forms.*'],
            completed: false,
          },
          {
            show: hasPermission('settings_manage'),
            disabled: !authOrganization.value.is_pro_account,
            name: 'Customer Portal',
            description:
              'Provide your customers with a branded experience to streamline their booking process.',
            routeIsInertia: true,
            targetRouteName: 'web.company.customer-portal.website.show',
            icon: DeviceTabletIcon,
            routeNamesToCheckIfCurrent: ['web.company.customer-portal.*'],
            badge: 'New!',
          },
        ],
      },
      {
        show: true,
        name: 'More',
        description: null,
        children: [
          {
            show: hasPermission('payroll_manage') && authCompany.value.is_payroll_enabled,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Payroll',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.company.payroll.pay-runs.index',
            icon: CurrencyDollarIcon,
            routeNamesToCheckIfCurrent: ['web.company.payroll.*', 'compensation-plans.*'],
          },
          {
            show: hasPermission('report_view_any'),
            disabled: !authOrganization.value.is_pro_account,
            name: 'Reports',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.company.reports.index',
            icon: ChartSquareBarIcon,
            routeNamesToCheckIfCurrent: ['reports.*'],
          },
          {
            show: true,
            name: 'App Store',
            description: null,
            routeIsInertia: false,
            targetRouteName: 'web.company.app-store.show',
            icon: LightningBoltIcon,
            routeNamesToCheckIfCurrent: ['web.company.app-store.show'],
          },
          {
            show: hasPermission('settings_manage'),
            name: 'Team',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.company.company-team-members.index',
            icon: UserAddIcon,
            routeNamesToCheckIfCurrent: ['web.company.company-team-members.*'],
          },
          {
            show: true,
            name: 'Settings',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.company.settings-overview.show',
            icon: CogIcon,
            routeNamesToCheckIfCurrent: ['web.company.settings-overview', 'web.company.settings.*'],
          },
          {
            show: authCompany.value.has_mileage_reports_enabled,
            disabled: !authOrganization.value.is_pro_account,
            name: 'Mileage Reports',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'web.company.reports.mileage.index',
            icon: TruckIcon,
            routeNamesToCheckIfCurrent: ['web.company.reports.mileage.index'],
          },
        ],
      },
    ],
  }
}
