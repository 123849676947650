<template>
  <div class="inline-flex items-center">
    <div :class="[bgColorClasses, shapeClasses]">
      <Typography :color="`${color}-dark`" :variant="textVariant" class="whitespace-nowrap">
        {{ text }}
      </Typography>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { Typography } from '@aryeo/runway'

const props = defineProps({
  text: {
    type: [String, Number],
    required: true,
  },
  textVariant: {
    type: String,
    default: 'label',
  },
  color: {
    type: String,
    default: 'success',
    validator: (value) =>
      ['warning', 'info', 'success', 'primary', 'danger', 'default'].includes(value),
  },
  circular: {
    type: Boolean,
    default: false,
  },
})

const shapeClasses = computed(() => {
  return props.circular ? 'rounded-full w-6 h-6 leading-6 text-center p-0' : 'rounded-md py-1 px-2'
})

const bgColorClasses = computed(
  () =>
    ({
      warning: 'bg-amber-100',
      info: 'bg-blue-100',
      success: 'bg-emerald-100',
      primary: 'bg-indigo-100',
      danger: 'bg-red-100',
      default: 'bg-gray-100',
    })[props.color]
)
</script>
