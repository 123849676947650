import { UsersIcon, CollectionIcon } from '@heroicons/vue/outline'

export default function useCustomerNavigation() {
  return {
    overviewTitle: 'Customer Center',
    overviewRouteName: 'web.company.customer-overview.show',
    sections: [
      {
        show: true,
        name: 'Manage',
        description:
          'Manage your customers and organize them into teams to easily manage complex pricing and billing configurations as your business grows.',
        children: [
          {
            show: true,
            name: 'Customers',
            description: 'All the customers you service.',
            routeIsInertia: true,
            targetRouteName: 'web.company.customer-users.index',
            icon: UsersIcon,
            routeNamesToCheckIfCurrent: ['web.company.customer-users.*'],
          },
          {
            show: true,
            name: 'Customer Teams',
            description:
              'Brokerages, offices, teams, or other groupings of customers that share pricing and billing settings.',
            routeIsInertia: true,
            targetRouteName: 'web.company.customer-teams.index',
            icon: CollectionIcon,
            routeNamesToCheckIfCurrent: ['web.company.customer-teams.*'],
            badge: 'NEW',
          },
        ],
      },
    ],
  }
}
