<script setup>
import { computed, ref } from 'vue'
import { router, usePage } from '@inertiajs/vue3'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { Avatar, Divider, Icon, Stack, Tooltip, Typography } from '@aryeo/runway'

import { useAuth } from '@/Hooks/Customer/useAuth.js'
import CustomerTeamUpdateDefaultMembershipModal from '@/Components/Customer/CustomerTeams/CustomerTeamUpdateDefaultMembershipModal.vue'

defineProps({
  darkMode: { type: Boolean, default: true },
})

const page = usePage()

const { authCustomerTeamMemberships, authCustomerTeamMembership } = useAuth()

const selectDefaultMembershipModalIsOpen = ref(false)
const selectDefaultMembershipIsLoading = ref(false)
const searchTerm = ref('')

const filteredCustomerTeamMemberships = computed(() => {
  return authCustomerTeamMemberships.value.data.filter((membership) => {
    return membership.customer_team.name.toLowerCase().includes(searchTerm.value.toLowerCase())
  })
})

const animateTeamSwitcherTooltip = ref(true)
const showTeamSwitcherTooltip = ref(page.props.newCustomerTeam ?? false)

setTimeout(() => {
  animateTeamSwitcherTooltip.value = false
}, 5000)

const isSelected = (membership) => {
  return authCustomerTeamMembership.value.id === membership.id
}

const handleSearch = ({ key, value }) => {
  searchTerm.value = value
}

const handleSelectCustomerTeamMembership = async (membershipId) => {
  router.put(
    route('web.customer.customer-team-memberships.selected.update', membershipId),
    {},
    {
      preserveState: false,
      onSuccess: () => {
        searchTerm.value = ''
      },
    }
  )
}

const handleSelectDefaultCustomerTeamMembership = (membershipId) => {
  selectDefaultMembershipIsLoading.value = true

  router.put(
    route('web.customer.customer-team-memberships.default.update', membershipId),
    {},
    {
      onSuccess: () => {
        selectDefaultMembershipModalIsOpen.value = false
        selectDefaultMembershipIsLoading.value = false
      },
    }
  )
}
</script>

<template>
  <Menu as="div" class="relative inline-block w-full my-auto text-left">
    <div>
      <MenuButton
        class="flex items-center justify-between w-full h-10 px-3 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light lg:rounded-md focus:ring-offset-primary-light"
        :class="
          darkMode
            ? 'text-header-font lg:hover:bg-header-dark'
            : 'text-header-dark hover:text-header-darkest hover:bg-header-off-white border border-header-lightest'
        "
        @click="showTeamSwitcherTooltip = false"
      >
        <span class="text-sm font-medium truncate">
          <span class="sr-only">Open customer team menu</span>
          {{ authCustomerTeamMembership.customer_team.name }}
        </span>
        <ChevronDownIcon class="block w-5 h-5 ml-1 shrink-0" aria-hidden="true" />

        <transition
          leave-active-class="transition duration-150"
          leave-from-class="scale-100 opacity-100"
          leave-to-class="scale-90 opacity-0"
        >
          <Tooltip
            v-if="showTeamSwitcherTooltip"
            placement="bottom"
            :contentInteractive="true"
            trigger="mouseenter focus"
            class="absolute -right-1"
          >
            <template #default>
              <div @mouseenter="animateTeamSwitcherTooltip = false" class="relative flex size-4">
                <Icon name="infoCircle" size="small" color="success" />
                <span
                  v-if="animateTeamSwitcherTooltip"
                  class="hidden motion-safe:inline-flex animate-ping absolute h-full w-full rounded-full bg-emerald-400 opacity-60"
                ></span>
              </div>
            </template>
            <template #content>
              <Stack class="p-2" space="xsmall">
                <Typography as="p"> Switch between team spaces here. </Typography>
              </Stack>
            </template>
          </Tooltip>
        </transition>
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="scale-95 opacity-0"
      enter-to-class="scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="scale-100 opacity-100"
      leave-to-class="scale-95 opacity-0"
    >
      <MenuItems
        class="absolute max-h-screen-75 overflow-y-scroll left-0 z-30 w-56 mt-1 px-2 py-3 origin-top-left bg-white rounded-md shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <MenuItem v-if="authCustomerTeamMemberships.data.length >= 10">
          <FormSearchInput
            :value="searchTerm"
            hasNoResultsMessage="No customer teams were found for your search."
            placeholderText="Search by team name..."
            @update:modelValue="handleSearch"
          />
        </MenuItem>
        <MenuItem
          v-for="customerTeamMembership in filteredCustomerTeamMemberships"
          :key="customerTeamMembership.id"
          @click.prevent="handleSelectCustomerTeamMembership(customerTeamMembership)"
        >
          <div class="group my-3 text-sm">
            <div
              class="flex flex-row justify-between w-full p-3 cursor-pointer"
              :class="{
                'border-primary border-[3px] rounded': isSelected(customerTeamMembership),
              }"
            >
              <div>
                <p class="text-gray-900 text-wrap">
                  {{ customerTeamMembership.customer_team.name }}
                </p>
                <p class="text-xs text-gray-500">
                  {{ `Team ${customerTeamMembership.role}` }}
                </p>
                <p v-if="customerTeamMembership.is_default" class="text-xs text-gray-900">
                  (Default)
                </p>
              </div>
              <Avatar
                class="shrink-0 ml-2"
                :image="customerTeamMembership.customer_team.logo_url"
                :firstName="customerTeamMembership.customer_team.name"
                borderRadius="large"
              />
            </div>
          </div>
        </MenuItem>
        <Divider class="my-2" />
        <div class="group flex flex-col gap-6 p-3 text-sm">
          <button
            class="text-primary text-left hover:bg-gray-100"
            @click.prevent="router.get(route('web.customer.customer-teams.create-or-join'))"
          >
            Create or join a team
          </button>
          <button
            class="text-gray-500 text-left hover:bg-gray-100"
            @click.prevent="selectDefaultMembershipModalIsOpen = true"
          >
            Change default
          </button>
        </div>
      </MenuItems>
    </transition>
  </Menu>
  <CustomerTeamUpdateDefaultMembershipModal
    :customerTeamMemberships="authCustomerTeamMemberships.data"
    :isOpen="selectDefaultMembershipModalIsOpen"
    @onClose="selectDefaultMembershipModalIsOpen = false"
    @onConfirm="handleSelectDefaultCustomerTeamMembership"
  />
</template>
